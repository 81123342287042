import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import { hyphenateUrlComponents } from '../../../../../../utils/urlHelpers/boats';
import get from 'lodash/get';
import find from 'lodash/find';
import FilterModel from '../FilterModel';
import isEqual from 'lodash/isEqual';

const FilterModels = ({
  makes = [],
  models = [],
  position,
  makeModels = {},
  handleDataChange,
  params,
  pageType
}) => {
  return makes.map((make) => {
    const slugMake = normalizeString(hyphenateUrlComponents(make.value));
    const selectedMakeModels = find(models, ['value', make.value]);
    if (get(makeModels, slugMake)) {
      return (
        <FilterModel
          key={slugMake}
          make={make}
          models={selectedMakeModels ? selectedMakeModels.model : []}
          makeModels={makeModels}
          position={position}
          handleDataChange={handleDataChange}
          params={params}
          pageType={pageType}
        />
      );
    }
    return null;
  }).filter(Boolean);
};

FilterModels.propTypes = {
  handleDataChange: PropTypes.func.isRequired,
  makeModels: PropTypes.object,
  makes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string
    })
  ),
  models: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string
    })
  ),
  params: PropTypes.shape({
    modal: PropTypes.string
  }).isRequired,
  position: PropTypes.string,
  pageType: PropTypes.string
};

export default memo(FilterModels, (oldProps, newProps) => {
  return isEqual(oldProps.makes, newProps.makes) &&
         isEqual(oldProps.models, newProps.models) &&
         isEqual(oldProps.makeModels, newProps.makeModels) &&
         isEqual(oldProps.params, newProps.params);
});
